import { Fragment } from 'react';
import LocationSearchBar from "../LocationUtils/LocationSearchBar";

const LocationPanel = () => {
    return (
        <Fragment>
            <h4>Where did you go?</h4>
            <LocationSearchBar />
        </Fragment>
    );
}

export default LocationPanel;