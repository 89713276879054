import { connect } from 'react-redux';
import './Header.css';
import HomeButton from './HomeButton';
import ProfileButton from './ProfileButton';

const Header = (props) => {
    return (
        <div className="Header">
            {/* <h1>{`Username: ${props.username}`}</h1> */}
            <ProfileButton />
            <HomeButton />
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        username: state.homeReducer.username
    }
  }

export default connect(mapStateToProps, null)(Header);