import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from './client-config';
import { connect } from 'react-redux'
import { changeAddress, changeCity, changeArea, changeState, changeMapPosition, changeMarkerPosition, changeLocation, changeConfiguration, onPlaceSelected } from '../Redux/actions/LocationActions';
import { getCity, getArea, getState } from './MapUtils';
import Autocomplete from 'react-google-autocomplete';
import '../LocationUtils/LocationSearchBar.css';
// import SearchButton from '../Home/SearchButton';
// import TimeSearchBar from '../TimeUtils/TimeSearchBar';
import { GOOGLE_MAPS_API_URL } from '../api';

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class Map extends Component {

	/**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	onMarkerDragEnd = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);
				this.props.changeConfiguration(
					(address) ? address : '',
					(area) ? area : '',
					(city) ? city : '',
					(state) ? state : '',
					newLat, newLng, newLat, newLng
				);
			},
			error => {
				console.error(error);
			}
		);
	};

	render() {
		console.log('rendering', this.props)
		const AsyncMap = withScriptjs(
			withGoogleMap(
				dummy => {
					let autocomplete = (
						<Autocomplete
							defaultValue={this.props.address}
							className="form-control"
							onPlaceSelected={this.props.onPlaceSelected}
							types={["geocode"]}>
						</Autocomplete>
					)
					if (this.props.locationLoading == true) {
						autocomplete = (
							<Autocomplete
								defaultValue="Loading..."
								disabled={true}
								className="LocationSearchBarLoading">
							</Autocomplete>
						);
					}
					if (this.props.mapVisible == false) {
						return autocomplete;
					}
					// let searchButton;
					// if (this.props.searchButtonVisible == true) {
					// 	searchButton = (
					// 		<div className="col-md-1 col-sm-12 p-2">
					// 			<SearchButton />
					// 		</div>
					// 	);
					// }
					return (
						<div>
							<div className="container">
								<div className="row LocationAndTimeContainer">
									<div className="col-md-6 col-sm-12 p-2">
										{autocomplete}
									</div>
									{/* <div className="col-md-5 col-sm-12 p-2">
										<TimeSearchBar date={this.props.date} time={this.props.time} changeDate={this.props.changeDate} changeTime={this.props.changeTime} />
									</div> */}
									{/* {searchButton} */}
								</div>
							</div>
							<GoogleMap google={this.props.google}
								defaultZoom={this.props.zoom}
								defaultCenter={this.props.mapPosition}>
								<Marker google={this.props.google}
									name={'Dallas, TX'}
									draggable={true}
									onDragEnd={this.onMarkerDragEnd}
									position={this.props.markerPosition}
								/>
								<Marker />
								{/* For Auto complete Search Box */}
							</GoogleMap>
						</div>
					);
				}
			)
		);
		let map;
		if (this.props.mapPosition.lat !== undefined) {
			map = <div >
				<AsyncMap
					googleMapURL={`${GOOGLE_MAPS_API_URL}?location=${this.props.mapPosition.lat},${this.props.mapPosition.lng}&radius=100&key=${GoogleMapsAPI}&libraries=places&types=address`}
					loadingElement={
						<div style={{ height: this.props.height }} />
					}
					containerElement={
						<div style={{ height: "fit-content" }} />
					}
					mapElement={
						<div style={{ height: this.props.height }} />
					}
				/>
			</div>
		} else {
			map = <div style={{ height: this.props.height }} />
		}
		return (map)
	}
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
		locationLoading: state.locationReducer.locationLoading,
		address: state.locationReducer.address,
		city: state.locationReducer.city,
		area: state.locationReducer.area,
		state: state.locationReducer.state,
		mapPosition: state.locationReducer.mapPosition,
		markerPosition: state.locationReducer.markerPosition,
	}
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
	return {
		changeAddress: address => dispatch(changeAddress(address)),
		changeCity: city => dispatch(changeCity(city)),
		changeArea: area => dispatch(changeArea(area)),
		changeState: state => dispatch(changeState(state)),
		changeMapPosition: (lat, lng) => dispatch(changeMapPosition(lat, lng)),
		changeMarkerPosition: (lat, lng) => dispatch(changeMarkerPosition(lat, lng)),
		changeLocation: (address, city, area, state) => dispatch(changeLocation(address, city, area, state)),
		changeConfiguration: (address, city, area, state, mapLat, mapLng, markerLat, markerLng) => dispatch(changeConfiguration(address, city, area, state, mapLat, mapLng, markerLat, markerLng)),
		onPlaceSelected: place => dispatch(onPlaceSelected(place))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);