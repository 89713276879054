import React, { Fragment } from 'react';
import LinkButton from '../Shared/LinkButton';
import './ProfileDropdown.css';

const ProfileDropdown = () => {
    return (
        <div className="ProfileDropdownOptions">
            <LinkButton className="ProfileDropdownOption" to="profile">Profile</LinkButton>
            <LinkButton className="ProfileDropdownOption" to="mytrips">My Trips</LinkButton>
        </div>
    )
}

export default ProfileDropdown;