import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import homeReducer from './reducers/HomeReducer';
import locationReducer from './reducers/LocationReducer';
import { createLogger } from 'redux-logger';

const rootReducer = combineReducers({
    homeReducer: homeReducer,
    locationReducer: locationReducer
});
  
const logger = createLogger();
  
const store = createStore(rootReducer, applyMiddleware(thunk, logger));

export default store;