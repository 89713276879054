const HotelTable = (props) => {

    const rows = props.hotelRows.map(row =>
        <div className="row">
            <div className="col-4">
                <input defaultValue={row.name}/>
            </div>
            <div className="col-4">
                <input defaultValue={row.price}/>
            </div>
            <div className="col-4">
                <input defaultValue={row.currency}/>
            </div>
        </div>
    );

    return (
        <div className="container">
            {rows}
        </div>
    );
}

export default HotelTable;