import DatesPanel from "./DatesPanel"
import InformationPanel from "./InformationPanel"
import LocationPanel from "./LocationPanel"
import SubmitPanel from "./SubmitPanel"

const TestPageTwo = () => {
    return <div>
        <InformationPanel>
            <LocationPanel />
        </InformationPanel>
        <InformationPanel>
            <DatesPanel />
        </InformationPanel>
        <InformationPanel>
            <SubmitPanel />
        </InformationPanel>
    </div>
}
export default TestPageTwo;