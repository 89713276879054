import LinkButton from '../Shared/LinkButton';
import { AWS_API_URL } from '../api';
import webFetch from '../Shared/Fetch';
import './Home.css';

const Home = () => {
    return (
        <div className="HomeStyle">
            <h1>Welcome to the Home Page</h1>
            <LinkButton to="one">Test Page One</LinkButton>
            <LinkButton to="two">Test Page Two</LinkButton>
            <LinkButton to="three">Test Page Three</LinkButton>
            <button onClick={getCookie}>Get Cookie</button>
            <button onClick={message}>Message</button>
        </div>
    );    
}

const getCookie = async () => {
    const accessToken = localStorage.getItem("aleflytAccessToken");
    const idToken = localStorage.getItem("aleflytIdToken");
    // const ret = await fetch(`${AWS_API_URL}/create-servlet-cookie?accessToken=${accessToken}`, { 
    //     credentials: 'include',
    //     headers: new Headers({
    //       'Authorization': `Bearer ${accessToken}`, 
    //     })
    //     // credentials: 'include'
    //   });
    // const json = ret.text();
    // console.log("Cookies?: " + json);
    const ret = await fetch(`${AWS_API_URL}/api/auth/cookie?accessToken=${accessToken}&idToken=${idToken}`, {
        headers: new Headers({
            'Authorization': `Bearer ${accessToken}`, 
        }),
        credentials: 'include'
    });
    const text = ret.text();
    console.log("received: " + text)
}

const message = async () => {
    const message = await webFetch(`${AWS_API_URL}/api/public/hello`);
    const ret = await message.text();
    console.log("message: " + ret);
}

export default Home;