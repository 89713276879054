import Trip from "./Trip";

import './Trips.css';

const Trips = (props) => {

    const trips = props.trips.map(trip => <Trip trip={trip}/>);

    return (
        <div className="Trips">
            {trips}
        </div>
    );
}

export default Trips