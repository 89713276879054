import { AWS_API_URL, AWS_COGNITO_URL, FRONT_END_HOSTNAME } from '../api';
import { getUsername, verifyAccessToken } from '../Auth/JWT';
import { changeUsername, changeUserIsLoggedIn } from '../Redux/actions/HomeActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userIsLoggedIn, accessTokenIsValid } from '../Auth/AuthenticationUtils';

const Login = (props) => {

    if (loginUrlContainsTokens()) {
        const tokens = extractTokensFromLoginUrl();
        console.log('tokens', tokens);
        if (accessTokenIsValid(tokens.access_token)) {
            saveTokensToLocalStorage(tokens);
            console.log("Token was valid");
            props.history.push('/home');
        }
        console.log("Token was not valid");
    }

    return (
        <div>
            <h1>Please Log In:</h1>
            <button onClick={redirectToCognito}>Log In</button>
        </div>
    )
}

const redirectToCognito = () => {
    window.location = `${AWS_COGNITO_URL}/login?response_type=token&client_id=4pj1qvo7p729s052vfmilrug11&redirect_uri=${FRONT_END_HOSTNAME}/login`;
}

const saveTokensToLocalStorage = (tokens) => {
    localStorage.setItem("aleflytAccessToken", tokens.access_token);
    localStorage.setItem("aleflytIdToken", tokens.id_token);
}

const loginUrlContainsTokens = () => {
    const tokens = {};
    const tokenPairs = window.location.hash.substring(1).split('&');
    tokenPairs.forEach(tokenPair => {
        const split = tokenPair.split('=');
        const key = split[0];
        const val = split[1];
        tokens[key] = val;
    });
    return tokens.access_token;
}

const extractTokensFromLoginUrl = () => {
    const tokens = {};
    const tokenPairs = window.location.hash.substring(1).split('&');
    tokenPairs.forEach(tokenPair => {
        const split = tokenPair.split('=');
        const key = split[0];
        const val = split[1];
        tokens[key] = val;
    }
    );
    return tokens;
}

// const checkWhetherLoggedIn = async (props) => {
//     //check for a cookie, otherwise check the URL
//     const tokens = {};
//     const tokenPairs = window.location.hash.substring(1).split('&');
//     tokenPairs.forEach(tokenPair => {
//         const split = tokenPair.split('=');
//         const key = split[0];
//         const val = split[1];
//         tokens[key] = val;
//     }
//     );
//     console.log('tokens', tokens);
//     const accessToken = tokens.access_token;
//     if (!accessToken) return;
//     const verifiedToken = await verifyAccessToken(accessToken);
//     console.log('verifiedToken', verifiedToken);
//     if(!verifiedToken) {
//         localStorage.removeItem('aleflytAccessToken');
//         localStorage.removeItem('aleflytIdToken');
//         props.changeUserIsLoggedIn(false);
//         props.changeUsername(null);
//         return;
//     }
//     const idToken = tokens.id_token;
//     const verifiedIdToken = await getUsername(idToken);
//     props.changeUserIsLoggedIn(true);
//     props.changeUsername(verifiedIdToken['cognito:username']);
//     localStorage.setItem("aleflytAccessToken", accessToken);
//     localStorage.setItem("aleflytIdToken", idToken);
// }

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        userIsLoggedIn: state.homeReducer.userIsLoggedIn
    }
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
    return {
        changeUserIsLoggedIn: (userIsLoggedIn) => dispatch(changeUserIsLoggedIn(userIsLoggedIn)),
        changeUsername: (username) => dispatch(changeUsername(username)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));