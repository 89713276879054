import './App.css';
import Home from './Home/Home';
import Profile from './Profile/Profile';
import MyTrips from './MyTrips/MyTrips';
import Error from './Error/Error';
import Login from './Login/Login';
import PrivateRoute from './Shared/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Header from './Header/Header';
import TestPageOne from './TestPages/TestPageOne';
import TestPageTwo from './TestPages/TestPageTwo';
import TestPageThree from './TestPages/TestPageThree';
import { userIsLoggedIn } from './Auth/AuthenticationUtils';

class App extends Component {

  render() {
    return (
      <div className="Content">
        <Header />
        <div className="Body">
          <Switch>
            {/* <Route path="/" component={Home} exact />
            <Route path="/home" component={Home} />
            <Route path="/one" component={TestPageOne} />
            <Route path="/two" component={TestPageTwo} />
            <Route path="/three" component={TestPageThree} />
            <Route component={Error} />  */}
            <Route path="/login" component={Login} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/" component={Home} exact />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/profile" component={Profile} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/mytrips" component={MyTrips} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/home" component={Home} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/one" component={TestPageOne} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/two" component={TestPageTwo} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} path="/three" component={TestPageThree} />
            <PrivateRoute isLoggedIn={userIsLoggedIn} component={Error} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    userIsLoggedIn: state.homeReducer.userIsLoggedIn
  }
}

export default connect(mapStateToProps)(App);
