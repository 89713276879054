import { getCity, getArea, getState } from '../../LocationUtils/MapUtils';

export const changeLocationLoading = (locationLoading) => {
    return {
        type: 'CHANGE_LOCATION_LOADING',
        locationLoading: locationLoading
    };
}

export const changeAddress = (address) => {
    return {
        type: 'CHANGE_ADDRESS',
        address: address
    };
}

export const changeCity = (city) => {
    return {
        type: 'CHANGE_CITY',
        city: city
    };
}

export const changeArea = (area) => {
    return {
        type: 'CHANGE_AREA',
        area: area
    };
}

export const changeState = (state) => {
    return {
        type: 'CHANGE_STATE',
        state: state
    };
}

export const changeMapPosition = (lat, lng) => {
    return {
        type: 'CHANGE_MAP_POSITION',
        lat: lat,
        lng: lng
    };
}

export const changeMarkerPosition = (lat, lng) => {
    return {
        type: 'CHANGE_MARKER_POSITION',
        lat: lat,
        lng: lng
    };
}

export const changeLocation = (address, city, area, state) => {
    return {
        type: 'CHANGE_LOCATION',
        address: address,
        city: city,
        area: area,
        state: state,
    };
}

export const changeConfiguration = (address, city, area, state, mapLat, mapLng, markerLat, markerLng) => {
    console.log("CHANGING_CONFIGURATION")
    return {
        type: 'CHANGE_CONFIGURATION',
        address: address,
        city: city,
        area: area,
        state: state,
        mapPosition: {
            lat: mapLat,
            lng: mapLng
        },
        markerPosition: {
            lat: markerLat,
            lng: markerLng
        }
    };
}

    /**
	 * When the user types an address in the search box
	 * @param place
	 */
	export const onPlaceSelected = ( place ) => {
        return async dispatch => {
            console.log( 'plc', place );
            const address = place.formatted_address,
                addressArray =  place.address_components,
                city = getCity( addressArray ),
                area = getArea( addressArray ),
                state = getState( addressArray ),
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng();
            // Set these values in the state.
            console.log(latValue, lngValue)
            dispatch(changeConfiguration(
                ( address ) ? address : '',
                ( area ) ? area : '',
                ( city ) ? city : '',
                ( state ) ? state : '',
                latValue, lngValue, latValue, lngValue
            ));
        }
	};