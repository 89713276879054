import './LocationSearchBar.css';
import Map from '../LocationUtils/Map';
import { connect } from 'react-redux'

const LocationSearchBar = (props) => {
    return <Map locationLoading={props.locationLoading} mapVisible={false}/>
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        locationLoading: state.locationReducer.locationLoading
    }
}

export default connect(mapStateToProps, null)(LocationSearchBar);