import LinkButton from '../Shared/LinkButton';
import './HomeButton.css';

const HomeButton = () => {

    return (
        <div className="HomeButtonContainer">
            <LinkButton to="home">
                <i className="HomeButton fas fa-home fa-2x"></i>
            </LinkButton>
        </div>
    );
}

export default HomeButton;