import { createRequest } from '../Redux/actions/HomeActions'
import { connect } from 'react-redux'

const SubmitPanel = (props) => {
    return (
        <button className="btn btn-primary" onClick={() => props.createRequest(props.address, props.fromDate, props.toDate)}>Submit</button>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
        address: state.locationReducer.address,
		fromDate: state.homeReducer.fromDate,
		toDate: state.homeReducer.toDate,
	}
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
	return {
		createRequest: (location, fromDate, toDate) => dispatch(createRequest(location, fromDate, toDate))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitPanel);