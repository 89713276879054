const initialState = {
    loading: false,
    text: '',
    address: '',
    city: '',
    area: '',
    state: '',
    mapPosition: {
        lat: 32.776665,
        lng: -96.796989
    },
    markerPosition: {
        lat: 32.776665,
        lng: -96.796989
    }
}

export default function LocationReducer(state = initialState, action) {
    switch (action.type) {
      case 'CHANGE_LOCATION_LOADING':
        return {
            ...state,
            locationLoading: action.locationLoading
        }
      case 'CHANGE_ADDRESS':
        return {
            ...state,
            address: action.address
        }
      case 'CHANGE_CITY':
        return {
            ...state,
            city: action.city
        }
      case 'CHANGE_AREA':
        return {
            ...state,
            area: action.area
        }
      case 'CHANGE_MAP_POSITION':
        return {
            ...state,
            mapPosition: {
                lat: action.lat,
                lng: action.lng
            }
        }
      case 'CHANGE_MARKER_POSITION':
        return {
            ...state,
            markerPosition: {
                lat: action.lat,
                lng: action.lng
            }
        }
      case 'CHANGE_LOCATION':
        return {
            ...state,
            address: action.address,
            city: action.city,
            area: action.area,
            state: action.state,
        }
      case 'CHANGE_CONFIGURATION':
            return {
                ...state,
                address: action.address,
                city: action.city,
                area: action.area,
                state: action.state,
                mapPosition: {
                    lat: action.mapPosition.lat,
                    lng: action.mapPosition.lng
                },
                markerPosition: {
                    lat: action.markerPosition.lat,
                    lng: action.markerPosition.lng
                }
            }
      default:
        return state
    }
  }