import store from '../Redux/store';
import { changeAccessToken, changeIdToken, changeUserIsLoggedIn } from '../Redux/actions/HomeActions';
import { useHistory } from "react-router-dom";
import { FRONT_END_HOSTNAME } from '../api';

const webFetch = async (url) => {
    const accessToken = localStorage.getItem("aleflytAccessToken");
    return fetch(url, {
        headers: new Headers({
            'Authorization': `Bearer ${accessToken}`
        }),
        credentials: 'include',
    })
    .then(handleErrors)
    .then(response => response)
    .catch(error => {
        console.log('flam', error);
        localStorage.removeItem("aleflytAccessToken");
        localStorage.removeItem("aleflytIdToken");
        window.location = `${FRONT_END_HOSTNAME}/login`;
    });
}

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export default webFetch;