import { AWS_COGNITO_JWTS } from '../api';

const jwt = require('jsonwebtoken');
const jwksClient = require('jwks-rsa');

const client = jwksClient({
    jwksUri: AWS_COGNITO_JWTS
});

const getKey = (header, callback) => {
    client.getSigningKey(header.kid, (err, key) => {
        // if (err) {
        //     console.log('JWT was invalid');
        //     return;
        // }
        const signingKey = key.publicKey || key.rsaPublicKey;
        callback(null, signingKey);
    });
}

export const getUsername = (idToken) => {
    return promisify(jwt.verify)(idToken, getKey)
        .then(result => {
            console.log('res id', result);
            return result;
        }).catch(err => {
            console.log(err);
        });
}

export const verifyAccessToken = (accessToken) => {
    return promisify(jwt.verify)(accessToken, getKey)
        .then(result => {
            console.log('res access', result);
            return result;
        }).catch(err => {
            console.log(err);
        });
}

const promisify = fn => {
    return (...args) => {
        return new Promise((resolve, reject) => {
            function customCallback(err, ...results) {
                if (err) {
                    return reject(err);
                }
                return resolve(results.length === 1 ? results[0] : results)
            }

            args.push(customCallback);
            fn.call(this, ...args);
        })
    }
}