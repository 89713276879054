import './Trip.css';

const Trip = (props) => {
    return (
        <div className="Trip">
            <h6>{props.trip.location}</h6>
            <h6>{props.trip.fromDate}</h6>
            <h6>{props.trip.toDate}</h6>
        </div>
    );
}

export default Trip;