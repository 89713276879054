const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();
  return `${yyyy}-${mm}-${dd}`;
}

const getLastWeekAgoDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 7);
  const yyyy = today.getFullYear();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();
  return `${yyyy}-${mm}-${dd}`;
}

const initialState = {
  username: '',
  userIsLoggedIn: false,
  accessToken: null,
  idToken: null,
  hotelRows: [],
  fromDate: getLastWeekAgoDate(),
  toDate: getTodayDate(),
  records: []
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_USERNAME':
      return {
        ...state,
        username: action.username
      }
    case 'CHANGE_USER_IS_LOGGED_IN':
      return {
        ...state,
        userIsLoggedIn: action.userIsLoggedIn
      }
    case 'CHANGE_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.accessToken
      }
    case 'CHANGE_ID_TOKEN':
      return {
        ...state,
        idToken: action.idToken
      }
    case 'ADD_HOTEL_ROW':
      return {
        ...state,
        hotelRows: action.hotelRows
      }
    case 'CHANGE_FROM_DATE':
      return {
        ...state,
        fromDate: action.fromDate
      }
    case 'CHANGE_TO_DATE':
      return {
        ...state,
        username: action.toDate
      }
    case 'CHANGE_RECORDS':
      return {
        ...state,
        records: action.records
      }
    default:
      return state
  }
}