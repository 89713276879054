import { Component } from "react";
import { connect } from "react-redux";
import Trips from "../MyTrips/Trips";
import { getAllRequests } from "../Redux/actions/HomeActions";

class TestPageThree extends Component {

    componentDidMount() {
        this.props.getAllRequests();
    }

    render() {
        return <Trips trips={this.props.records}/>
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        records: state.homeReducer.records
    }
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
    return {
        getAllRequests: () => dispatch(getAllRequests())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPageThree);