import React, { Component } from 'react';
import './ProfileButton.css';
import ProfileDropdown from './ProfleDropdown';

class ProfileButton extends Component {

    state = {
        open: false
    }

    toggleDropdown = () => {
        this.setState({ open: !this.state.open });
        console.log(this.state.open);
    }
    
    render() {

        let profileDropdown = null;

        if (this.state.open) {
            profileDropdown = <ProfileDropdown />;
        }

        return (
            <div className="ProfileButtonContainer" onClick={this.toggleDropdown}>
                <i className="ProfileButton fas fa-user-circle fa-2x"></i>
                {profileDropdown}
            </div>
        );
    }
}

export default ProfileButton;