import { AWS_COGNITO_JWTS } from '../api';

const jwt = require('jsonwebtoken');
const jwksClient = require('jwks-rsa');

const client = jwksClient({
    jwksUri: AWS_COGNITO_JWTS
});

export const userIsLoggedIn = () => {
    const accessToken = localStorage.getItem("aleflytAccessToken");
    if (!accessToken) return false;
    return accessTokenIsValid(accessToken);
}

export const accessTokenIsValid = (accessToken) => {
    return promisify(jwt.verify)(accessToken, getKey)
        .then(result => {
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
}

const getKey = (header, callback) => {
    client.getSigningKey(header.kid, (err, key) => {
        // if (err) {
        //     console.log('JWT was invalid');
        //     return;
        // }
        const signingKey = key.publicKey || key.rsaPublicKey;
        callback(null, signingKey);
    });
}

const promisify = fn => {
    return (...args) => {
        return new Promise((resolve, reject) => {
            function customCallback(err, ...results) {
                if (err) {
                    return reject(err);
                }
                return resolve(results.length === 1 ? results[0] : results)
            }

            args.push(customCallback);
            fn.call(this, ...args);
        })
    }
}