import './DatesPanel.css';
import { connect } from 'react-redux'
import { changeFromDate, changeToDate } from '../Redux/actions/HomeActions';

const DatesPanel = (props) => {
    return (
        <div>
            <h4>Which dates did you visit?</h4>
            <div className="container p-0 m-0">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-xs-12">
                        <h6>From</h6>
                        <input type="date" value={props.fromDate} onChange={props.changeFromDate}/>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-12">
                        <h6>To</h6>
                        <input type="date" value={props.toDate} onChange={props.changeToDate}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
		fromDate: state.homeReducer.fromDate,
		toDate: state.homeReducer.toDate,
	}
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
	return {
		changeFromDate: fromDate => dispatch(changeFromDate(fromDate)),
		changeToDate: toDate => dispatch(changeToDate(toDate)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DatesPanel);