
export let FRONT_END_HOSTNAME = "https://www.aleflyt.com";
export let AWS_API_URL = 'https://api.aleflyt.com';
export let GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/js';
export let AWS_COGNITO_URL = 'https://auth.aleflyt.com';
export let AWS_COGNITO_JWTS = 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_BZMJ7jF8m/.well-known/jwks.json';

//local

// FRONT_END_HOSTNAME = "http://localhost:3000"
// AWS_API_URL = 'http://localhost:8080';