import './TestPageOne.css';
import HotelTable from './HotelTable';
import { connect } from 'react-redux';
import { addHotelRow } from '../Redux/actions/HomeActions';
import LocationSearchBar from '../LocationUtils/LocationSearchBar';

const TestPageOne = (props) => {

    const rows = [
        {
            'name': 'Hotel 1',
            'price': '120',
            'currency': 'USD'
        },
        {
            'name': 'Hotel 2',
            'price': '50',
            'currency': 'USD'
        },
        {
            'name': 'Hotel 3',
            'price': '200',
            'currency': 'USD'
        }
    ]

    return (
        <div className="ParentBox">
            <h1 className="Title">Let's Recreate Your Adventure!</h1>
            <div className="ChildBox">
                <h3 className="BoxTitle">Where did you go? (We don't need the specifics yet, an overview will do!)</h3>
                <LocationSearchBar />
                <div className="AddField">+Location</div>
            </div>
            <div className="ChildBox">
                <h3 className="BoxTitle">When was your trip?</h3>
                <div className="BabyBox">
                    <p>From To</p>
                </div>
                <ul>
                    <li>Here's List Item #1</li>
                    <li>Here's List Item #2</li>
                </ul>
            </div>
            <div className="ChildBox">
                <h3 className="BoxTitle">Duration:</h3>
                <h3 className="BoxTitle">days</h3>
            </div>
            {/* <div className="ChildBox">
                <h3 className="BoxTitle">Where all did you stay while you were there?</h3>
                <div className="BabyBox">
                    <p1>Hotel Name Search Bar</p1>
                </div>
                <div className="CostBabyBox">
                    <p>Total Cost Box</p>
                </div>
                <div className="CostBabyBox">
                    <p>Currency Dropdown</p>
                </div>
                <div className="AddField">+Hotel, Hostel, Apartment...</div>
            </div> */}
            <HotelTable hotelRows={props.hotelRows} />
            <button onClick={() => props.onAddHotelRow(props.hotelRows)}>Add A Hotel</button>
            <div className="ChildBox">
                <h3 className="BoxTitle">Now let's jump into the specifics.</h3>
            </div>
            <div className="ChildBox">
                <h3 className="BoxTitle">What did you do on Day _?</h3>
            </div>
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        hotelRows: state.homeReducer.hotelRows
    }
}

const mapDispatchToProps = (dispatch, /*, ownProps*/) => {
    return {
        onAddHotelRow: (hotelRows) => dispatch(addHotelRow(hotelRows))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPageOne);