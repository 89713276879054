import { AWS_API_URL } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import webFetch from '../../Shared/Fetch';

export const changeUsername = (username) => {
    return {
        type: 'CHANGE_USERNAME',
        username: username
    };
}

export const changeUserIsLoggedIn = (userIsLoggedIn) => {
    return {
        type: 'CHANGE_USER_IS_LOGGED_IN',
        userIsLoggedIn: userIsLoggedIn
    };
}

export const changeAccessToken = (accessToken) => {
    return {
        type: 'CHANGE_ACCESS_TOKEN',
        accessToken: accessToken
    };
}

export const changeIdToken = (idToken) => {
    return {
        type: 'CHANGE_ID_TOKEN',
        idToken: idToken
    };
}

export const addHotelRow = (hotelRows, index) => {
    console.log(hotelRows);
    const newHotelRows = hotelRows.concat(
        {
            'name': 'Hotel 1',
            'price': '120',
            'currency': 'USD'
        }
    );
    return {
        type: 'ADD_HOTEL_ROW',
        hotelRows: newHotelRows
    };
}

export const changeFromDate = (event) => {
    return {
        type: 'CHANGE_FROM_DATE',
        fromDate: event.target.value
    };
}

export const changeToDate = (event) => {
    return {
        type: 'CHANGE_TO_DATE',
        toDate: event.target.value
    };
}

export const changeRecords = (records) => {
    return {
        type: 'CHANGE_RECORDS',
        records: records
    };
}

export const createRequest = (location, fromDate, toDate) => {
    return async dispatch => {
        const requestObj = {
            id: uuidv4(),
            location: location,
            fromDate: fromDate,
            toDate: toDate
        }
        console.log(`attempting to request creation for ${JSON.stringify(requestObj)}`)
        const res = await fetch(`${AWS_API_URL}/api/public/create`, {
            method: 'POST',
            body: JSON.stringify(requestObj),
            headers: new Headers({
                'Authorization': `Bearer ${localStorage.getItem("aleflytAccessToken")}`
            }),
            credentials: 'include',
        });
        const text = await res.text();
        console.log(text);
    }
}

export const getAllRequests = () => {
    return async dispatch => {
        console.log(`attempting to get all open requests`);
        const res = await webFetch(`${AWS_API_URL}/api/public/getAll`);
        const json = await res.json();
        console.log(json);
        dispatch(changeRecords(json));
    }
}
